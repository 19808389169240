const language = [
    // Finnish
    {
        // Global yes/no btn
        'yes': 'Kyllä',
        'no': 'Ei',
        // Login
        'loginNav': 'Männistön puutarha',
        'loginWarning': 'Salasana tai nimi on väärin.',
        'username': 'Käyttäjänimi',
        'password': 'Salasana',
        'login': 'Kirjaudu sisään',
        // Frontpage
        'collect': 'Kerättävät',
        'ready': 'Valmiit',
        'fpRullakot': 'Rullakot ja hyllyt',
        'fpKalenteri': 'Kalenteri',
        'fpAutoKukka': 'Kukat automaatti täydennykseen',
        'fpAutoAsiakas': 'Asiakkaat automaatti täydennykseen',
        'fpKukkaWork': 'Kukkien tiedot tallennettu!',
        'fpKukkaFail': 'Kukkien tiedoissa on jokin virhe tai tietokanta on alhaalla tai ei ole toiminta kunnossa!',
        'fpKauppaWork': 'Kauppojen tiedot tallennettu!',
        'fpKauppaFail': 'Kauppojen tiedoissa on jokin virhe tai tietokanta on alhaalla tai ei ole toiminta kunnossa!',
        'fpUserAdd': 'Käyttäjien lisääminen ja poistaminen.',
        'fpUser': 'Käyttäjänimi',
        'fpRole': 'Rooli',
        'fpPassword': 'Salasana',
        'fpCreate': 'Luo käyttäjä',
        'fpDelete': 'Poistetaanko käyttäjä?',
        //To be collected
        'statusBar1': 'Odottaa keräystä',
        'statusBar2': 'Keräyksessä',
        'statusBar3': 'Kerätty',
        'statusBar4': 'Ei ole',
        'tuote': 'Tuote',
        'kerataan': 'Kerätään',
        'kerayspiste': 'Keräyspiste',
        'lisatietoa': 'Lisätietoa',
        'keraamassa': 'Keräämässä',
        'kerattymaara': 'Kerättymäärä',
        'tarkastettuR': 'Ryönä tarkastettu: ',
        'tarkastettuT': 'Tuusjärvi tarkastettu: ',
        // TBC buttons
        'valmis': 'Valmis',
        'arkistoi': 'Arkistoi',
        'muokkaa': 'Muokkaa',
        'poista': 'Poista',
        'vieExceliin': 'Vie Exceliin',
        'talPDF': 'Tulosta taulukko',
        // Ready table
        'ready1': 'Haluatko viedä tämän taulun valmiina oleviin?',
        'ready11': 'Haluatko arkistoida tämän taulun?',
        'ready2': 'Onko kaikki jo kerätty?',
        'ready3': 'ID: ',
        'ready4': 'Kauppa: ',
        'ready5': 'Keräyspäivämäärä: ',
        'ready6': 'Toimituspäivämäärä: ',
        'ready7': 'Kerättävien kohteiden määrä: ',
        'ready8': 'Kerätty: ',
        'ready9': 'Ei ole: ',
        // Edit table
        'kerayspvm': 'Keräyspäivämäärä',
        'toimituspvm': 'Toimituspäivämäärä',
        'addflower': 'Lisää kukka',
        'paivita': 'Päivitä taulukon tiedot',
        // Delete table
        'delete1': 'Haluatko poistaa tämän taulun?',
        'delete2': 'Kukkien määrä: ',
        // Printing tables
        'tulosta': 'Tulosta',
        'kauppa': 'Kauppa',
        'tarrat': 'Tarrojen määrä',
        // Nav
        'navKeraysPVM': 'Keräyspäivämäärä',
        'navToimitusPVM': 'Toimituspäivämäärä',
        'luotaulukko': 'Luo taulukko',
        'logout': 'Kirjaudu ulos',
        'navCollect': 'Kerättävät',
        'navReady': 'Valmiit',
        'navArchived': 'Arkistoitu',
        'tarkastettuAnswerYes': 'Kyllä',
        'tarkastettuAnswerNo': 'Ei',
        'search': 'Etsi',
        'navFlowers': 'kukkia',
        'navKaupat': 'kauppoja',
        'navProgressBar': 'Eteneminen',
        //Calendar nav
        'navCalendar': 'Kalenteri',
        // Calendar
        'calendarToimitettavat': 'Viikon toimitettavat kaupat',
        'calendarKerattavat': 'Viikon kerättävät kaupat',
        // Trolleys and shelves
        'trolleyKauppa': 'Kauppa: ',
        'trolleyLaina': 'Rullakot lainassa',
        'trolleySave': 'Tallenna',
        'trolleyAdded': 'Lisätty',
        'trolleyAmount': 'Rullakoiden määrä',
        'shelfLaina': 'Hyllyt lainassa',
        'shelfAmount': 'Hyllyjen määrä',
        'trolleyPalautettu': 'Rullakot palautettu',
        'trolleyAddedL': 'Lisätty viimeeksi',
        'trolleyAddedA': 'Lisätty määrä',
        'shelfPalautettu': 'Hyllyt palautettu',
        'trolleys': 'Rullakot',
        'shelves': 'Hyllyt',
        'trolleyK': 'Rullakko: ',
        'trolleyKA': 'Rullakoiden määrä: ',
        'shelfK': 'Hylly: ',
        'shelfKA': 'Hyllyjen määrä: ',
        // Trolleys and shelves
        'navRullakot': 'Rullakot ja hyllyt',
        // Extra
        'kokonaisMaara': 'Kokonais määrät',
    },
    // English language[localStorage.getItem('language')].
    {
        // Global yes/no btn
        'yes': 'Yes',
        'no': 'No',
        // Login
        'loginNav': 'Männistön puutarha',
        'loginWarning': 'Password or username is wrong.',
        'username': 'Username',
        'password': 'Password',
        'login': 'Log in',
        // Frontpage
        'collect': 'To be collected',
        'ready': 'Completed orders',
        'fpRullakot': 'Trolley\'s\ and shelves',
        'fpKalenteri': 'Calendar',
        'fpAutoKukka': 'Flower\'s\ for automatic fill',
        'fpAutoAsiakas': 'Customer\'s\ for automatic fill',
        'fpKukkaWork': 'Flower\'s\ data saved!',
        'fpKukkaFail': 'Flower\'s\ data had some sort of error or database is down or isn\'t\ in working order!',
        'fpKauppaWork': 'Store\'s\ data saved',
        'fpKauppaFail': 'Store\'s\ data had some sort of error or database is down or isn\'t\ in working order!',
        'fpUserAdd': 'Add user\'s\ or delete them.',
        'fpUser': 'Username',
        'fpRole': 'Role',
        'fpPassword': 'Password',
        'fpCreate': 'Create user',
        'fpDelete': 'Delete user?',
        // To be collected
        'statusBar1': 'Awaiting collecting',
        'statusBar2': 'Collecting',
        'statusBar3': 'Collected',
        'statusBar4': 'Not available',
        'tuote': 'Product',
        'kerataan': 'Amount to be collected',
        'kerayspiste': 'Collection point',
        'lisatietoa': 'More information',
        'keraamassa': 'Collecting',
        'kerattymaara': 'Amount collected',
        'tarkastettuR': 'Ryönä inspected: ',
        'tarkastettuT': 'Tuusjärvi inspected: ',
        // TBC buttons
        'valmis': 'Ready',
        'arkistoi': 'Archive',
        'muokkaa': 'Edit',
        'poista': 'Delete',
        'vieExceliin': 'Export to Excel',
        'talPDF': 'Print table',
        // Ready table
        'ready1': 'Do you want to take this table to the ready tables?',
        'ready11': 'Do you want to archive this table?',
        'ready2': 'Has everything been collected?',
        'ready3': 'ID: ',
        'ready4': 'Store: ',
        'ready5': 'Date of collection: ',
        'ready6': 'Delivery date: ',
        'ready7': 'Amount of products: ',
        'ready8': 'Collected: ',
        'ready9': 'Not available: ',
        // Edit table
        'kerayspvm': 'Date of collection',
        'toimituspvm': 'Delivery date',
        'addflower': 'Add Flower',
        'paivita': 'Update table',
        // Delete table
        'delete1': 'Do you want delete this table?',
        'delete2': 'Flowers: ',
        // Printing tables
        'tulosta': 'Print',
        'kauppa': 'Store',
        'tarrat': 'Amount of stickers',
        // Nav
        'navKeraysPVM': 'Collection date',
        'navToimitusPVM': 'Delivery date',
        'luotaulukko': 'Create a table',
        'logout': 'Log out',
        'navCollect': 'To be collected',
        'navReady': 'Ready',
        'navArchived': 'Archived',
        'tarkastettuAnswerYes': 'Yes',
        'tarkastettuAnswerNo': 'No',
        'search': 'Search for',
        'navFlowers': 'flowers',
        'navKaupat': 'stores',
        'navProgressBar': 'Progress bar',
        // Calendar nav
        'navCalendar': 'Calendar',
        // Calendar
        'calendarToimitettavat': 'Weekly orders to be delivered',
        'calendarKerattavat': 'Weekly orders to be collected',
        // Trolley's and shelves
        'trolleyKauppa': 'Store: ',
        'trolleyLaina': 'Trolley\'s\ borrowed',
        'trolleySave': 'Save',
        'trolleyAdded': 'Added',
        'trolleyAmount': 'Trolley\'s\ amount added',
        'shelfLaina': 'Shelves borrowed',
        'shelfAmount': 'Shelves amount added',
        'trolleyPalautettu': 'Trolley\'s\ returned',
        'trolleyAddedL': 'Added last time',
        'trolleyAddedA': 'Added amount',
        'shelfPalautettu': 'Shelves returned',
        'trolleys': 'Trolley\'s\ ',
        'shelves': 'Shelves',
        'trolleyK': 'Trolley: ',
        'trolleyKA': 'Amount of trolleys: ',
        'shelfK': 'Shelf: ',
        'shelfKA': 'Amount of shelves: ',
        // Trolley nav
        'navRullakot': 'Trolley\'s\ and shelves',
        // Extra
        'kokonaisMaara': 'Entirety of orders',
    }
    // language[localStorage.getItem('language')].
]
export default language;