// Server
export const FETCH_URL = "https://mannisto.a2hosted.com/";
export const SOCKET_URL = "https://mannisto.a2hosted.com/";


// Localhost
//export const FETCH_URL = "http://localhost:3002/";
//export const SOCKET_URL = "http://localhost:3002/";

// Ngrok
//export const FETCH_URL = "";
//export const SOCKET_URL = "";