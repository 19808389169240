import React, { useEffect, useState } from "react";
import XLSX from "xlsx";

function ExcelImport() {
  const [allOrders, setOrders] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [buttonBlock, setButtonBlock] = useState(true);

  const handleFile = (e) => {
    try {
      var file = e.target.files[0];
      // input canceled, return
      if (!file) return;

      var FR = new FileReader();
      FR.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        let i;
        let orders = [];
        for (i = 0; i < result.length; i++) {
          let kukka = result[i];
          let k;
          let woa = [];
          for (k = 0; k < kukka.length; k++) {
            let data = kukka[k];
            woa.push(data);
          }
          orders.push(woa);
        }
        setOrders(orders);
        setButtonBlock(false);
      };
      FR.readAsArrayBuffer(file);
    } catch (error) {
      console.log(error);
    }
  };

  async function createOrdersFromExcel() {
    if (!allOrders) return;
    let i;
    setButtonBlock(true)
    setLoading(true);
    for (i = 1; i < allOrders[0].length; i++) {
      //console.log(allOrders[i]);
      let k;
      let ids = [];
      let orderData = null;
      for (k = 3; k < allOrders.length; k++) {
        if (k !== 0) {
          if (
            (allOrders[k][i] > 0 && !isNaN(allOrders[k][i])) ||
            (allOrders[k][i] !== "" && !isNaN(allOrders[k][i]))
          ) {
            /* This console.log is just for testing purposes!
            console.log({
              kukka: allOrders[k][0],
              maara: allOrders[k][i],
              kerayspaikka: allOrders[k][1],
            });*/
            let oneID = await fetch("https://mannisto.a2hosted.com/products/post", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("userData"),
              },
              body: JSON.stringify({
                kukka: allOrders[k][0],
                toimi: allOrders[k][i],
                kerays: allOrders[k][1],
              }),
            });
            let json = await oneID.json();
            ids.push(json.createdProduct._id);
          }
        }
      }
      orderData = {
        tpvm: allOrders[2][i],
        kpvm: allOrders[3][i],
        code: allOrders[1][i],
        kauppa: allOrders[0][i],
      };
      //console.log(orderData);
      if (ids.length !== 0) {
        createOrder(ids, orderData);
      }
    }
    setLoading(false);
    setOrders(null);
  }

  function createOrder(ids, orderData) {
    if (!ids) return;
    if (!orderData) return;
    fetch("https://mannisto.a2hosted.com/orders/post/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("userData"),
      },
      body: JSON.stringify({
        kauppa: orderData.kauppa,
        date: orderData.kpvm,
        toimituspvm: orderData.tpvm,
        orderLisatieto: orderData.code,
        products: ids,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <hr />
      <h4>Excel tiedoston sisään tuonti</h4>
      <div>
        <input
          type="file"
          accept=".xls,.xlsx,.ods"
          onChange={(e) => handleFile(e)}
        ></input>
        <div style={{"color": "lightseagreen"}}>
          {allOrders
            ? "Tiedosto luettu! Paina lisää nappia tietojen luomiseksi!"
            : null}
        </div>
      </div>
      <div style={{ "paddingTop": "5px" }}>
        <button disabled={buttonBlock} onClick={() => createOrdersFromExcel()}>Lisää</button>
      </div>
      <div>
          <h1 style={isLoading ? {"opacity": "1"} : {"opacity": "0", "transition": "opacity 3s ease-in-out"}}>{isLoading ? "Odota tietoja ladataan!" : "Kaikki tiedot on ladattu!"}</h1>
      </div>
    </div>
  );
}

export default ExcelImport;
